html{

  height: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* @media (min-width: 769px) {

  #table {
    display: none;
  }

} */
