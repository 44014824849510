.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: lightblue;
  height: 56px;
  z-index:999;
}

.navbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}


.navbar__logo{
    margin-left: 1rem ;
}

.navbar__logo a {

    color: white;
    text-decoration: none;
    font-size: 1.2rem;
 }

 .spacer {
     flex: 1;
 }

 
.navbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

}

.navbar_navigation-items li {
    padding: 0 0.5rem;
}


 .navbar_navigation-items a {
     color: white;
     text-decoration: none;
 }

 .navbar_navigation-items a:hover,
 .navbar_navigation-items a:active {
     color: purple;
 }


/* Media Queries */

/* Mobile */
@media screen and (max-width: 480px){
    .navbar_navigation-items {
        display: none;
    }

    /* moves logo in mobile mode */
    /* .navbar__logo {
        margin-left: 10rem
    } */

}

@media (min-width: 500px) {
    .navbar__toggle-button {
        display: none;
    }

    .navbar__logo {
        margin-left:0;
    }
}