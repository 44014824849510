body  {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    /* color: #fff; */
    /* font-size: 0.5em; */
    line-height: 1.5;
    text-align: center;
    padding-top: 80px;
  padding-bottom: 50px;
  }

  #about {

    padding-top: 0px;
  }