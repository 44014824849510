  
  h1,
  h2,
  h3 {
    margin: 0;
    padding: 1em 0;
  }
  
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  
  p {
    margin: 0;
    padding: 1em 0;
  }
  
  /* Header Showcase */
  
  #banner {
    min-height: 450px;
    text-align: center;
  }
  
  /* #banner h1 {
    padding-top: 100px;
    padding-bottom: 0;
  } */

  #banner_home h1{
    text-align: left;
  }

  
  #banner .content-wrap,
  #section-a .content-wrap {
    padding: 0 1.5em;
  }
  
  /* Section A */
  
  #section-a {
    background: #eaeaea;
    color: #333;
    padding-bottom: 2em;
  }
  
  /* Section B */
  
  #section-b {
    padding: 2em 1em 1em;
  }
  
  #section-b ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  #section-b li {
    margin-bottom: 1em;
    background: #fff;
    color: #333;
  }
  
  .card-content {
    padding: 1.5em;
  }
  
  /* Section C */
  
  #section-c {
    background: #fff;
    color: #333;
    padding: 2em;
  }
  
  /* Section D / Boxes */
  
  #section-d .box {
    padding: 2em;
    color: #fff;
  }
  
  #section-d .box:first-child {
    background: #2690d4;
  }
  
  /* Footer */
  
  #main-footer {
    padding: 2em;
    background: #000;
    color: #fff;
    text-align: center;
  }
  
  #main-footer a {
    color: #2690d4;
    text-decoration: none;
  }
  
  /* Media Queries */
  
  @media (min-width: 700px) {
    /* .grid {
      display: grid;
      grid-template-columns: 1fr repeat(2, minmax(auto, 25em)) 1fr;
    } */
    
    .content-wrap h1{
        padding: 4.5em;
    }

    #section-a .content-text {
      columns: 2;
      column-gap: 2em;
    }
  
    #section-a .content-text p {
      padding-top: 0;
    }
  
    .content-wrap,
    #section-b ul {
      grid-column: 2/4;
    }
  
    .box,
    #main-footer div {
      grid-column: span 2;
    }
  
    #section-b ul {
      display: flex;
      justify-content: space-around;
    }
  
    #section-b li {
      width: 31%;
    }
  }

  @media (max-width: 1100px) {

  #banner_home {
    display: none;
}
  }