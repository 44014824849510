@media (min-width: 1100px) {
    #mobile_image {
        display: none;
    }
}

@media (max-width: 1100px) {
    #desktop_image {
        display: none;
    }
}