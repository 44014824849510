
  
  #modal li{
    line-height: 0.5cm;
  }


  @media (max-width: 1100px) {

    #table {
      display: none;
  }

  #mobile-card {
      display: none;
  }

}

@media (min-width: 1100px) {
    #modal {
        display: none;
    }
}